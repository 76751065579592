
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import store from "@/store";

export default defineComponent({
  name: "CZPositionReportDashboard",
  components: {},
  setup() {
    const dashboard = ref({
      savings_budget: "",
      mtm: "",
      budget: "",
      baseline: "",
      mtm_cost: "",
      budget_cost: "",
      Fixed: "",
      Floating: "",
      FixedPercent: "",
      FloatingPercent: "",
      market: "",
      lockedSavings: "",
      Supplier: "",
      AccountManager: "",
    });

    const loading = ref<boolean>(false);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Dashboard", ["Flexible Procurement"]);
      getDashboardContent();
    });

    watch(
      () => [
        store.getters.layoutConfig("flexibleProcurement.scenarioName"),
        store.getters.layoutConfig("flexibleProcurement.reportName"),
        store.getters.layoutConfig("flexibleProcurement.current"),
      ],
      function () {
        getDashboardContent();
      }
    );

    function getDashboardContent() {
      loading.value = true;
      let AccountId = store.getters.AccountId;
      let current = store.getters.layoutConfig("flexibleProcurement.current");
      let FPreportName = store.getters.layoutConfig(
        "flexibleProcurement.reportName"
      );
      let FPscenarioName = store.getters.layoutConfig(
        "flexibleProcurement.scenarioName"
      );
      const accountInfo: any = {
        clientId: AccountId,
        productId: 4,
        reportId: FPreportName,
        scenarioId: FPscenarioName,
        iscurrent: current,
      };
      ApiService.post("api/FlexProcurement/getFlexProcrement", accountInfo)
        .then(async ({ data }) => {
          const response = await data;
          dashboard.value = {
            savings_budget:
              current == 0
                ? response.cumulative_values[0].savings_budget
                : response.cumulative_values[1].savings_budget,
            mtm:
              current == 0
                ? FPreportName == 1
                  ? response.cumulative_values[0].mtm + " p/th"
                  : response.cumulative_values[0].mtm + " £/MWh"
                : FPreportName == 1
                ? response.cumulative_values[1].mtm + " p/th"
                : response.cumulative_values[1].mtm + " £/MWh",
            budget:
              current == 0
                ? FPreportName == 1
                  ? response.cumulative_values[0].budget + " p/th"
                  : response.cumulative_values[0].budget + " £/MWh"
                : FPreportName == 1
                ? response.cumulative_values[1].budget + " p/th"
                : response.cumulative_values[1].budget + " £/MWh",
            baseline:
              current == 0
                ? FPreportName == 1
                  ? response.cumulative_values[0].baseline + " p/th"
                  : response.cumulative_values[0].baseline + " £/MWh"
                : FPreportName == 1
                ? response.cumulative_values[1].baseline + " p/th"
                : response.cumulative_values[1].baseline + " £/MWh",
            mtm_cost:
              current == 0
                ? response.cumulative_values[0].mtm_cost
                : response.cumulative_values[1].mtm_cost,
            budget_cost:
              current == 0
                ? response.cumulative_values[0].budget_cost
                : response.cumulative_values[1].budget_cost,
            FixedPercent:
              current == 0
                ? response.cumulative_values[0].fixed_per
                : response.cumulative_values[1].fixed_per,
            FloatingPercent:
              current == 0
                ? response.cumulative_values[0].floating_per
                : response.cumulative_values[1].floating_per,
            Fixed: response.costSummary.Fixed,
            Floating: response.costSummary.floating,
            market: response.costSummary.market,
            lockedSavings: response.locked_savings,
            Supplier: response.supplier_Name,
            AccountManager: response.manager,
          };
          loading.value = false;
        })
        .catch((a) => {
          loading.value = false;
          console.log("Api Log Error", a);
        });
    }

    /**
     *@description For adding the euro symbol and comma separaters
     */
    // function StringFormat(value) {
    //   let val = new String(value);
    //   let vals = val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //   return vals;
    // }

    return {
      dashboard,
      // StringFormat,
      loading,
    };
  },
});
